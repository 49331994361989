<template>
  <v-stepper style="background-color: transparent !important; margin-bottom: 40px" elevation="0" v-model="currentStep">
    <v-stepper-header>
      <v-stepper-step :step="step1" class="text-body-1" :complete="step1 < completeStep"> Prepare </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :step="step2" class="text-body-1" :complete="step2 < completeStep">
        Approve & Confirm</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step :step="step3" class="text-body-1" :complete="step3 < completeStep"> Send</v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
export default {
  props: {
    completeStep: Number,
    currentStep: Number,
  },
  data() {
    return {
      step1: 1,
      step2: 2,
      step3: 3,
    }
  },
}
</script>